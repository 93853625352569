import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Seo } from "../components/Seo";
import React from 'react';
import Caret from '../components/icons/Caret';
import Facebook from '../components/icons/Facebook';
import Instagram from '../components/icons/Instagram';
import LinkedIn from '../components/icons/LinkedIn';
import TeamHeadshots from '../components/TeamHeadshots';
import Cta from '../components/Cta';
import Button from '../components/Button';

const getPronoun = (term) => {
  switch (term) {
    case 'He/Him':
      return 'him';
    case 'She/Her':
      return 'her';
    case 'They/Them':
      return 'them';
    default:
      return 'them';
  }
}

const TeamMemberTemplate = ({pageContext}) => {
  const data = pageContext.data.primary;
  const team = pageContext.team.filter(e => e.id !== pageContext.data.id);
  return (
    <main>
      <section className="bg-white py-16">
        <div className="xl:container mx-auto grid lg:grid-cols-2 px-6 gap-x-20 gap-y-4 items-start mb-12">
          <div className="lg:col-span-2 mb-8">
            <h3 className="text-3xl md:text-4xl font-black text-sourci-pink">About Us</h3>
            <a className="text-sm hover:underline text-slate-300" href="/about-us">{'<'} Back</a>
          </div>
          <div className="lg:col-span-2">
            <h1 className="text-4xl md:text-5xl font-black mb-2">
              {data.first_name} {data.last_name}
            </h1>
            <div className="flex flex-row w-full">
              <h4 className="text-2xl font-black grow">{data.title}</h4>
              <ul className="grid grid-cols-3 gap-x-3">
                <li>
                  <a href={data.facebook_link.url}
                    target="_blank"
                    rel="noreferrer">
                    <Facebook className="w-8" />
                  </a>
                </li>
                <li>
                  <a href={data.linkedin_link.url}
                    target="_blank"
                    rel="noreferrer">
                    <LinkedIn className="w-8" />
                  </a>
                </li>
                <li>
                  <a href={data.instagram_link.url}
                    target="_blank"
                    rel="noreferrer">
                    <Instagram className="w-8" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="lg:col-span-2 flex flex-row items-center mb-4">
            <div className="border-b-2 border-solid border-sourci-teal grow mr-4"></div>
            <Caret className="w-5" />
          </div>

          <div>
            <GatsbyImage image={getImage(data.headshot)}
              alt={data.headshot.alt || ''}
              className="rounded-3xl" />
          </div>
          <div className="pt-4">
            <div className="text-2xl mb-4 grid gap-y-6"
              dangerouslySetInnerHTML={{__html: data.bio.html}}></div>
          </div>
        </div>
        <div className="py-16 bg-[#f8f8f8]">
          <div className="xl:container mx-auto grid lg:grid-cols-2 gap-x-16 px-6">
            <div className="mb-10 lg:mb-0">
              <p className="font-black text-3xl text-sourci-teal mb-4">
                You'll find {getPronoun(data.gender_pronoun)}
              </p>
              <div className="text-2xl font-bold grid gap-y-6"
                dangerouslySetInnerHTML={{__html: data.youll_find_him.html}}></div>
            </div>
            <div>
              <p className="font-black text-3xl text-sourci-teal mb-4">
                Coffee?
              </p>
              <p className="text-2xl font-bold mb-8">{data.coffee_preference}</p>
              <p className="font-black text-2xl lg:text-3xl">Get in touch with {data.first_name} at</p>
              <p className="font-black text-2xl lg:text-3xl text-sourci-pink">
                <a href={`mailto:${data.email}`}>{data.email}</a>
              </p>
            </div>
          </div>
          
        </div>
        <div className="pb-16 pt-24">
          <div className="xl:container mx-auto grid lg:grid-cols-3 px-6 gap-y-16 gap-x-16">
            <TeamHeadshots team={team} />
          </div>
        </div>
      </section>
      <Cta bg="sourci-pink"
        image={pageContext.data.banner}>
        <div className="text-center text-white w-full py-16">
          <p className="text-4xl md:text-5xl font-black">Go ahead,</p>
          <p className="text-4xl md:text-5xl font-black mb-12">Hit the <span className="text-black">start button</span></p>
          <Button label="Get in touch today"
            to="/contact"
            type="link"
            className="bg-sourci-teal hover:bg-sourci-teal-100 transition-colors text-white text-lg md:text-xl font-black" />
        </div>
      </Cta>
    </main>
  )
}

export default TeamMemberTemplate;

export function Head({pageContext}) {
  const data = pageContext.data.primary;
  return (
    <Seo title={`${data.first_name} ${data.last_name} | Our Team | Sourci`}
      description={`Meet ${data.first_name} ${data.last_name}, Sourci's ${data.title}`}
      canonical={`/about-us/${data.url_slug}`} />
  )
}